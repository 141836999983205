import React, { useEffect, useState } from "react"
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode"
import { connect } from "react-redux"
import { BlueButton } from "../../../../common"
import {
  setOrderSackDeleveredByBarcode,
  clearSetOrderSackDeleveredByBarcode,
} from "../../../../../actions"
import NotificationModal from "../../../../Modals/NotificationModal"
import "./sackScanner.scss"

export function SackScanner(props) {
  const [scanResult, setScanResult] = useState(null)
  const [manualSerialNumber, setManualSerialNumber] = useState("")
  const [scanner, setScanner] = useState(null)
  const [successMsg, setSuccessMsg] = useState("")
  const [lastScannedSack, setLastScannedSack] = useState("")

  useEffect(() => {
    let scn = new Html5QrcodeScanner("reader", {
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      qrbox: {
        width: 300,
        height: 200,
      },
      fps: 5,
    })
    setScanner(scn)
    return () => {
      if (scn) {
        scn.clear()
      }
      setScanResult(null)
      setScanner(null)
      setManualSerialNumber("")
      props.clearSetOrderSackDeleveredByBarcode()
    }
  }, [])

  useEffect(() => {
    if (scanner) {
      scanner.render(success, error)
      function success(result) {
        setScanResult(result)
      }
      function error(err) {
        console.warn(err)
      }
    }
  }, [scanner])

  useEffect(() => {
    if (!scanResult) {
      return
    }
    props.setOrderSackDeleveredByBarcode(scanResult)
  }, [scanResult])

  const onManualInput = (number) => {
    props.setOrderSackDeleveredByBarcode(number)
    setManualSerialNumber("")
  }

  useEffect(() => {
    if (props.deliveredOrdersSackBybarcodeSuccess) {
      if (props.deliveredOrdersSackBybarcode?.orderStatus == 22) {
        setSuccessMsg('הזמנה עודכנה לסטטוס "יצא מהמחסן"')
      }
      if (props.deliveredOrdersSackBybarcode?.orderStatus == 12) {
        setSuccessMsg('הזמנה עודכנה לסטטוס "הוחזר לשולח"')
      }
      setLastScannedSack(props.deliveredOrdersSackBybarcode)
    }
  }, [props.deliveredOrdersSackBybarcodeSuccess])

  const clearState = () => {
    setSuccessMsg("")
    props.clearSetOrderSackDeleveredByBarcode()
  }

  return (
    <div className="sackScanner">
      <div className="sackScanner-wrapper">
        <h1>סריקת שקים</h1>
        <div>
          <div id="reader"></div>
          <p className="center-text">או הכנס את הברקוד ידני:</p>
          <div className="center-input">
            <input
              type="text"
              value={manualSerialNumber}
              onChange={(evt) => setManualSerialNumber(evt.target.value)}
            />
            <BlueButton
              type="submit"
              extraClass="btn-manual"
              onClick={() => onManualInput(manualSerialNumber)}
            >
              שלח
            </BlueButton>
          </div>
          <p>
            {lastScannedSack
              ? `ברקוד אחרון שנסרק: ${lastScannedSack.barcode}`
              : ""}
          </p>
        </div>
        <BlueButton
          type="submit"
          extraClass="btn-back"
          onClick={() => props.history.push("/HomeScreen")}
        >
          חזרה
        </BlueButton>
      </div>
      <NotificationModal
        type={"success"}
        show={successMsg}
        title={"סריקת שקים"}
        text={successMsg}
        onOkClick={() => clearState()}
      />
      <NotificationModal
        type={"error"}
        show={props.deliveredOrdersSackBybarcodeFail}
        title={"סריקת שקים"}
        text={"שגיא. שק לא נסרק"}
        onOkClick={() => clearState()}
      />
      <NotificationModal
        type={"warning"}
        show={props.deliveredOrdersSackBybarcodeConflit}
        title={"סריקת שקים"}
        text={"שק כבר נמסר"}
        onOkClick={() => clearState()}
      />
    </div>
  )
}

const mapStateToProps = ({ orderSack }) => {
  const {
    deliveredOrdersSackBybarcode,
    deliveredOrdersSackBybarcodeSuccess,
    deliveredOrdersSackBybarcodeFail,
    deliveredOrdersSackBybarcodeConflit,
    isLoading,
    isError,
  } = orderSack
  return {
    deliveredOrdersSackBybarcode,
    deliveredOrdersSackBybarcodeSuccess,
    deliveredOrdersSackBybarcodeFail,
    deliveredOrdersSackBybarcodeConflit,
    isLoading,
    isError,
  }
}

export default connect(mapStateToProps, {
  setOrderSackDeleveredByBarcode,
  clearSetOrderSackDeleveredByBarcode,
})(SackScanner)
